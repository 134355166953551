import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";

export const InfoListTraining = inject("store")(
  observer(({store: {api}}) => {
    const [distance, setDistance] = useState("-");
    const [training, setTraining] = useState("-");
    const [corporat, setCorporat] = useState("-");

    useEffect(() => {
      api.getTrainingNum().then((data) => {
        setDistance(data.training_distance);
        setTraining(data.training_training);
        setCorporat(data.training_corporat);
      });
    }, []);

    return (
      <div className="info-list-training">
        <div className="info-list-training--text">Подпишитесь и оставайтесь в курсе</div>
        <div className="info-list-training--icons">
          <div>
            <div className="icon online"></div>
            <div className="info two-line">
              <div>{distance}</div>
              <div>Дистанционных курсов</div>
            </div>
          </div>
          <div>
            <div className="icon training"></div>
            <div className="info">
              <div>{training}</div>
              <div>Тренинга</div>
            </div>
          </div>
          <div>
            <div className="icon corporat"></div>
            <div className="info two-line">
              <div>{corporat}</div>
              <div>Корпоративных мероприятий</div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

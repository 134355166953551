import React from "react";
import {Col} from "antd";
import {NavLink} from "react-router-dom";

export const ItemSectionTraining = ({id, name, about, img}) => {
  return (
    <Col xs={24} md={12} lg={8} xl={8}>
      <NavLink to={`/training/${id}`}>
        <div className="item-train">
          <div className="img">
            <img src={img} width="100%" />
          </div>
          <div className="title">{name}</div>
          <div className="about">{about}</div>
        </div>
      </NavLink>
    </Col>
  );
};

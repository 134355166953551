import React from "react";
import {Avatar, Col} from "antd";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";

export const ItemFeedback = ({text, user_name, position, img}) => {
  return (
    <Col xs={24} md={24} lg={12} xl={12}>
      <div className="item-feedback">
        <div className="text">{text}</div>
        <div className="name">{user_name}</div>
        <div className="position">{position}</div>
        <div className="img">
          <Avatar src={img} size={72} icon={<UserOutlined />} />
        </div>
      </div>
    </Col>
  );
};

import HelperStore from "./helper/helper.store";
import ApiStore from "./api/api.store";
import SupportStore from "./support/support.store";

class RootStore {
  constructor() {
    this.helper = HelperStore;
    this.api = ApiStore;
    this.support = SupportStore;
  }
}

export default new RootStore();

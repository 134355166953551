import React, {Fragment, useEffect, useState} from "react";
import {ItemNew} from "./item.new";
import {Button, Row} from "antd";
import {ItemBigNew} from "./item-big.new";
import {FiArrowDown} from "react-icons/fi";
import {WrapperDiv} from "../../../hoc/wrapper-div";
import {inject, observer} from "mobx-react";
import {constNumTrainingMax, constNumTrainingStep} from "../../../consts/nums.const";

export const NewMain = inject("store")(
  observer(({store: {api}}) => {
    const [items, setItems] = useState([]);
    const [showBtn, setShowBtn] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    useEffect(() => {
      api.getTrainingLast().then((data) => {
        setItems(data.data);
        setShowBtn(data.showMore);
        setLoadingBtn(false);
      });
    }, [api.numTrainingMain]);

    const showMore = () => {
      setLoadingBtn(true);
      api.setNumTrainingMain(api.numTrainingMain + constNumTrainingStep);
    };

    return (
      <WrapperDiv>
        <div className="new-main">
          <h2>Новинки академии</h2>
          <span>
            Список учебных материалов академии постоянно пополняется. Следите за
            новостями.
          </span>
          <Row gutter={[16, 24]}>
            {items &&
              items.map((item, index) => {
                if (index === 1) {
                  return (
                    <Fragment key={index}>
                      <ItemBigNew {...items[1]} className="big-item-first" />
                      <ItemNew {...item} className="second-item-small" />
                    </Fragment>
                  );
                } else if (index === 3) {
                  return (
                    <ItemBigNew {...items[1]} key={index} className="big-item-second" />
                  );
                } else {
                  return <ItemNew {...item} key={index} />;
                }
              })}
          </Row>
          {showBtn && items.length < constNumTrainingMax && (
            <div className="wrapper-btn">
              <Button
                type="primary"
                className="arrow"
                onClick={showMore}
                loading={loadingBtn}
              >
                ПОКАЗАТЬ ЕЩЁ <FiArrowDown />
              </Button>
            </div>
          )}
        </div>
      </WrapperDiv>
    );
  })
);

import React from "react";
import {Col} from "antd";
import {trainingConst} from "../../../consts/training.const";
import {NavLink} from "react-router-dom";

export const ItemBigNew = ({
  id,
  title,
  about,
  type,
  publication,
  show_publication,
  date,
  show_date,
  img,
  className,
}) => {
  const training = trainingConst.find((item) => item.value === +type);

  return (
    <Col xs={24} md={24} lg={16} xl={16} className={className}>
      <NavLink to={`/training-info/${id}`}>
        <div className="item-big-new">
          <div className="img-wrapper">
            <img src={img} width="100%" />
            <div className="item-big-new--img-overlay"></div>

            {show_publication && (
              <div className="item-big-new--img-calendar">
                <span>{publication}</span>
              </div>
            )}
            <div className="item-big-new--date">
              <div>{show_date ? date : " "}</div>
              <div className={training ? training.className : ""}>
                {training ? training.title : "-"}
              </div>
            </div>
            <div className="title-wrapper">
              <div className="item-big-new--title">{title}</div>
              <div className="item-big-new--about">{about}</div>
            </div>
          </div>
        </div>
      </NavLink>
    </Col>
  );
};

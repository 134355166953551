import React from "react";
import {ReactComponent as IcoTime} from "../../../static/ico/ico_whatch.svg";

export const ItemTraining = ({title, about, img, time, is_new}) => {
  return (
    <div className="item-new">
      <div className="item-new--img">
        <div className="img-wrapper">
          <img src={img} width="100%" />
          <div className="item-new--img-overlay"></div>
        </div>
      </div>
      <div className="item-new--date">
        <div className="time">
          <IcoTime />
          {time}
        </div>
        <div className={is_new ? "new" : "empty"}>new</div>
      </div>
      <div className="item-new--title">{title}</div>
      <div className="item-new--about">{about}</div>
    </div>
  );
};

import React from "react";
import {InfoTrainingInfo} from "./info.training-info";
import {EnrollmentTrainingInfo} from "./enrollment.training-info";

export const ContentTrainingInfo = ({info}) => {
  return (
    <div className="content-info">
      <div className="about">
        {info.about}
        <div className="content-info--form">
          <EnrollmentTrainingInfo titleTraining={info.title} />
        </div>
      </div>
      <div className="info">
        <InfoTrainingInfo {...info} />
      </div>
    </div>
  );
};

import React from "react";
import {Col} from "antd";

export const ItemTeamAbout = ({id, name, position, img, handleClick}) => {
  return (
    <Col xs={24} md={8} lg={6} xl={6}>
      <div className="item-team" onClick={() => handleClick(id)}>
        <div className="img">
          <img src={img} width="100%" />
        </div>
        <div className="title">{name}</div>
        <div className="position">{position}</div>
      </div>
    </Col>
  );
};

import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
// local files
import {AdvancedAbout} from "./advanced.about";
import {modalNames} from "../../../consts/modal.const";
import {ModalComponent} from "../../../components/modal.component";

export const WorkerInfoModal = inject("store")(
  observer(({store: {helper}}) => {
    const [visible, setVisible] = useState();

    useEffect(() => {
      if (helper.modalName === modalNames.WORKER_INFO) {
        setVisible(true);
      }

      if (!helper.modalName) {
        setVisible(false);
      }
    }, [helper.modalName]);

    return (
      <ModalComponent
        className="refuse-modal"
        handleCancel={() => helper.updateModalName(undefined)}
        cancelText=""
        visible={visible}
      >
        <AdvancedAbout />
      </ModalComponent>
    );
  })
);

import React from "react";
import {AboutPage} from "./components/about-page";
import {WorkerInfoModal} from "./components/worker-info.modal";

export const About = () => {
  return (
    <>
      <AboutPage />
      <WorkerInfoModal />
    </>
  );
};

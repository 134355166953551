import React from "react";
import {WrapperDiv} from "../../../hoc/wrapper-div";
import {ListTrainingComponent} from "../../ListTraining/components/list.training";
import {SubscribeListTraining} from "../../ListTraining/components/subscribe.list-training";
import {InfoListTraining} from "../../ListTraining/components/info.list-training";

export const ContentCorporat = () => {
  return (
    <div className="content">
      <WrapperDiv>
        <ListTrainingComponent corporat />
        <SubscribeListTraining />
        <InfoListTraining />
      </WrapperDiv>
    </div>
  );
};

import React, {useCallback, useEffect, useState} from "react";
import {Button} from "antd";
import {inject, observer} from "mobx-react";
import {FieldControl, FieldGroup, FormBuilder, Validators} from "react-reactive-form";
import {showError, showSuccess} from "../services/notifications.service";
import {InputReactiveFormComponent} from "./Input-rf";

// eslint-disable-next-line react/display-name
export const SpamComponent = inject("store")(
  observer(({store: {support}}) => {
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [invalidForm, setInvalidForm] = useState(true);

    useEffect(() => {
      form.valueChanges.subscribe(() => {
        setInvalidForm(form.invalid);
      });
    }, []);

    const form = FormBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });

    const submit = useCallback(() => {
      setLoadingBtn(true);
      support.sendSubscribe(form.value).then((data) => {
        if (data.status === "ok") {
          showSuccess(data.mess);
          form.reset();
        } else {
          showError(data.mess);
        }
        setLoadingBtn(false);
      });
    }, [loadingBtn]);

    return (
      <div className="spam-component">
        <FieldGroup
          control={form}
          render={() => (
            <FieldControl
              name="email"
              render={InputReactiveFormComponent}
              meta={{
                placeholder: "Ваш email",
                name: "email",
              }}
            />
          )}
        />
        <Button
          type="primary"
          className="spam-btn"
          disabled={invalidForm}
          loading={loadingBtn}
          onClick={() => submit()}
        >
          ДА
        </Button>
      </div>
    );
  })
);

import axiosInstance from "../../api/api";

export class HelperAction {
  toggleSidebar(value) {
    this.showBar = value;
  }
  updateModalName(name) {
    this.modalName = name;
  }

  getBreadcrumb(params) {
    const config = {params: params};
    return axiosInstance.get("breadcrumb", config).then(({data}) => data);
  }
}

import React from "react";
import {HeaderCorporat} from "./components/header.corporat";
import {ContentCorporat} from "./components/content.corporat";
import {Breadcrumb} from "../../components/breadcrumb";

export const CorporatPage = () => {
  return (
    <div className="trainings-page">
      <HeaderCorporat />
      <Breadcrumb />
      <ContentCorporat />
    </div>
  );
};

import React from "react";
import {WrapperDiv} from "../../../hoc/wrapper-div";

export const HeaderTrainingInfo = ({title, section}) => {
  const sectionName = section ? section.title : "";
  return (
    <div className="header-info">
      <WrapperDiv>
        <div className="section">{sectionName}</div>
        <div className="title">{title}</div>
      </WrapperDiv>
    </div>
  );
};

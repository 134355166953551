import React, {useCallback, useEffect, useState} from "react";
import {Button} from "antd";
import {FiArrowRight} from "react-icons/fi";
import {FieldControl, FieldGroup, FormBuilder, Validators} from "react-reactive-form";
import {InputReactiveFormComponent} from "./Input-rf";
import {showError, showSuccess} from "../services/notifications.service";
import {inject, observer} from "mobx-react";
import {TextareaReactiveFormComponent} from "./textarea-rf";

export const SupportForm = inject("store")(
  observer(({store: {support}}) => {
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [invalidForm, setInvalidForm] = useState(true);

    useEffect(() => {
      form.valueChanges.subscribe(() => {
        setInvalidForm(form.invalid);
      });
    }, []);

    const form = FormBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      subject: [""],
      text: ["", [Validators.required]],
    });

    const submit = useCallback(() => {
      setLoadingBtn(true);
      support.sendSupport(form.value).then((data) => {
        if (data.status === "ok") {
          showSuccess(data.mess);
          form.reset();
        } else {
          showError(data.mess);
        }
        setLoadingBtn(false);
      });
    }, [loadingBtn]);

    return (
      <div className="support-form">
        <FieldGroup
          control={form}
          render={() => (
            <div className="Content">
              <div>
                <FieldControl
                  name="name"
                  render={InputReactiveFormComponent}
                  meta={{
                    placeholder: "Ваше имя *",
                    name: "name",
                  }}
                />
              </div>
              <div>
                <FieldControl
                  name="email"
                  render={InputReactiveFormComponent}
                  meta={{
                    placeholder: "E-mail *",
                    name: "email",
                  }}
                />
              </div>
              <div>
                <FieldControl
                  name="subject"
                  render={InputReactiveFormComponent}
                  meta={{
                    placeholder: "Тема сообщения",
                    name: "subject",
                  }}
                />
              </div>
              <div>
                <FieldControl
                  name="text"
                  render={TextareaReactiveFormComponent}
                  meta={{
                    placeholder: "Текст... *",
                    name: "text",
                  }}
                />
              </div>
            </div>
          )}
        />
        <div className="wrapper-btn">
          <Button
            type="primary"
            className="arrow"
            disabled={invalidForm}
            loading={loadingBtn}
            onClick={() => submit()}
          >
            ОТПРАВИТЬ <FiArrowRight />
          </Button>
        </div>
      </div>
    );
  })
);

import React from "react";
import {HeaderTraining} from "./components/header.training";
import {ContentTraining} from "./components/content.training";
import {Breadcrumb} from "../../components/breadcrumb";

export const TrainigPage = () => {
  return (
    <div className="trainings-page">
      <HeaderTraining />
      <Breadcrumb />
      <ContentTraining />
    </div>
  );
};

import * as React from "react";
import {Input} from "antd";
const {TextArea} = Input;

export const TextareaReactiveFormComponent = ({handler, touched, hasError, meta}) => {
  return (
    <label
      className={
        touched && (hasError("required") || hasError("minLength") || hasError("email"))
          ? "input-component Error"
          : "input-component"
      }
    >
      <TextArea
        autoSize={{
          minRows: 4,
          maxRows: 6,
        }}
        type="text"
        placeholder={meta.placeholder}
        {...handler()}
      />
    </label>
  );
};

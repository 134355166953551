import React, {useCallback, useEffect, useState} from "react";
import {Button} from "antd";
import {FiArrowRight} from "react-icons/fi";
import {FieldControl, FieldGroup, FormBuilder, Validators} from "react-reactive-form";
import {InputReactiveFormComponent} from "../../../components/Input-rf";
import {inject, observer} from "mobx-react";
import {showError, showSuccess} from "../../../services/notifications.service";

export const EnrollmentTrainingInfo = inject("store")(
  observer(({store: {support}, titleTraining}) => {
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [invalidForm, setInvalidForm] = useState(true);

    const form = FormBuilder.group({
      fio: ["", [Validators.required]],
      department: [""],
      position: [""],
      email: ["", [Validators.required, Validators.email]],
      phone_in: [""],
      phone: [""],
    });

    useEffect(() => {
      form.valueChanges.subscribe(() => {
        setInvalidForm(form.invalid);
      });
    }, []);

    const submit = useCallback(() => {
      setLoadingBtn(true);
      support.sendEnrollment({...form.value, title: titleTraining}).then((data) => {
        if (data.status === "ok") {
          showSuccess(data.mess);
          form.reset();
        } else showError(data.mess);
        setLoadingBtn(false);
      });
    }, [loadingBtn]);

    return (
      <div className="enrollment-training-info">
        <h3>Заявка на участие</h3>
        <FieldGroup
          control={form}
          render={() => (
            <div className="Content">
              <div>
                <FieldControl
                  name="fio"
                  render={InputReactiveFormComponent}
                  meta={{
                    placeholder: "ФИО *",
                    name: "fio",
                  }}
                />
              </div>
              <div>
                <FieldControl
                  name="department"
                  render={InputReactiveFormComponent}
                  meta={{
                    placeholder: "Дирекция",
                    name: "department",
                  }}
                />
              </div>
              <div>
                <FieldControl
                  name="position"
                  render={InputReactiveFormComponent}
                  meta={{
                    placeholder: "Должность",
                    name: "position",
                  }}
                />
              </div>
              <div>
                <FieldControl
                  name="email"
                  render={InputReactiveFormComponent}
                  meta={{
                    placeholder: "Email *",
                    name: "email",
                  }}
                />
              </div>
              <div className="tel">
                <FieldControl
                  name="phone_in"
                  render={InputReactiveFormComponent}
                  meta={{
                    placeholder: "Внутренний тел.",
                    name: "phone_in",
                  }}
                />
                <FieldControl
                  name="phone"
                  render={InputReactiveFormComponent}
                  meta={{
                    placeholder: "Моб тел.",
                    name: "phone",
                  }}
                />
              </div>
            </div>
          )}
        />
        <div className="wrapper-btn">
          <Button
            type="primary"
            className="arrow"
            disabled={invalidForm}
            loading={loadingBtn}
            onClick={() => submit()}
          >
            ХОЧУ УЧАСТВОВАТЬ <FiArrowRight />
          </Button>
        </div>
      </div>
    );
  })
);

import React from "react";
import {MapContacts} from "./components/map.contacts";
import {ContentContacts} from "./components/content.contacts";
import {WrapperDiv} from "../../hoc/wrapper-div";

export const Contacts = () => {
  return (
    <div className="contacts-page">
      <MapContacts />
      <WrapperDiv>
        <ContentContacts />
      </WrapperDiv>
    </div>
  );
};

import React from "react";
import {WrapperDiv} from "../../../hoc/wrapper-div";
import {TeamAbout} from "./team.about";
import {getConst} from "../../../services/storage.service";

export const AboutPage = () => {
  const title = getConst("about_header_title");
  const about = getConst("about_header_about");

  return (
    <div className="about-page">
      <div className="about-page--header">
        <WrapperDiv>
          <div className="wrapper-bg">
            <div>
              <div className="title">Академия Финбридж</div>
              <div className="about">{title}</div>
            </div>
            <div>
              <div className="text" dangerouslySetInnerHTML={{__html: about}}></div>
            </div>
          </div>
        </WrapperDiv>
      </div>
      <WrapperDiv>
        <div className="wrapper-content">
          <h2>Наша команда</h2>
          <div className="items">
            <TeamAbout />
          </div>
        </div>
      </WrapperDiv>
    </div>
  );
};

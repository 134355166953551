import React, {useCallback} from "react";
import {inject, observer} from "mobx-react";
import {FiX} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import {getConst} from "../services/storage.service";

export const Sidebar = inject("store")(
  observer(({store: {helper}}) => {
    const email = getConst("email");
    const link_instagramm = getConst("link_instagramm");
    const link_youtube = getConst("link_youtube");

    const close = useCallback(() => {
      helper.toggleSidebar(false);
    }, [helper]);

    return (
      <div className={`${helper.showBar ? "show" : ""}`}>
        <div className="overlay" onClick={close}></div>
        <div className="sidebar">
          <FiX onClick={close} className="ico-close" title="Закрыть" />
          <div className="sidebar--menu">
            <div>
              <ul className="top-ul">
                <li>
                  <NavLink to="/distance" activeClassName="active" onClick={close}>
                    Дистанционное обучение
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/courses" activeClassName="active" onClick={close}>
                    Тренинги
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/corporat" activeClassName="active" onClick={close}>
                    Корпоративные мероприятия
                  </NavLink>
                </li>
              </ul>
            </div>

            <div>
              <ul className="bot-ul">
                <li>
                  <NavLink to="/about" activeClassName="active" onClick={close}>
                    Об академии
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/feedback" activeClassName="active" onClick={close}>
                    Отзывы
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contacts" activeClassName="active" onClick={close}>
                    Контакты
                  </NavLink>
                </li>
              </ul>
              <NavLink to="/" onClick={close}>
                <div className="sidebar-logo"></div>
              </NavLink>
              <div className="sidebar-mail">
                <a href={link_youtube} target="_blank" rel="noopener noreferrer">
                  <div className="ico_youtube"></div>
                </a>
                <a href={link_instagramm} target="_blank" rel="noopener noreferrer">
                  <div className="ico_instagramm"></div>
                </a>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

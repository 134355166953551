import React from "react";
import {SupportForm} from "../../../components/support.form";
import {ReactComponent as IcoEmail} from "../../../static/ico/ico_email.svg";
import {ReactComponent as IcoPhone} from "../../../static/ico/ico_phone.svg";
import {ReactComponent as IcoPin} from "../../../static/ico/ico_pin.svg";
import {ReactComponent as IcoInsta} from "../../../static/ico/ico_insta.svg";
import {ReactComponent as IcoYoutube} from "../../../static/ico/ico_youtube.svg";
import {getConst} from "../../../services/storage.service";

export const ContentContacts = () => {
  const address = getConst("address");
  const email = getConst("email");
  const phone = getConst("phone");
  const phone_link = getConst("phone_link");
  const link_instagramm = getConst("link_instagramm");
  const link_youtube = getConst("link_youtube");

  return (
    <div className="content-contacts">
      <div>
        <h2>Контакты</h2>
        <div>
          <IcoEmail />
          <a href={`mailto:${email}`} className="link">
            {email}
          </a>
        </div>
        <div>
          <IcoPhone />
          <a href={`tel:${phone_link}`}>{phone}</a>
        </div>
        <div>
          <IcoPin />
          {address}
        </div>
        <div className="icons">
          <a href={link_instagramm} target="_blank" rel="noopener noreferrer">
            <IcoInsta />
          </a>
          <a href={link_youtube} target="_blank" rel="noopener noreferrer">
            <IcoYoutube />
          </a>
        </div>
      </div>
      <div>
        <SupportForm />
      </div>
    </div>
  );
};

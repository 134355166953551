import React from "react";

export const UnderSliderMain = () => {
  return (
    <div className="under-slider-main">
      <div>
        <div className="under-slider-main--icon ico-online"></div>
        <div className="under-slider-main--title">Онлайн - курсы</div>
        <div className="under-slider-main--about">
          Дистанционное обучение 24/7, из любой точки мира
        </div>
      </div>
      <div>
        <div className="under-slider-main--icon ico-training"></div>
        <div className="under-slider-main--title">Тренинги</div>
        <div className="under-slider-main--about">
          Для профессионального и личностного развития
        </div>
      </div>
      <div>
        <div className="under-slider-main--icon ico-corporat"></div>
        <div className="under-slider-main--title">Корпоративные мероприятия</div>
        <div className="under-slider-main--about">
          Проведение свободного времени с коллегами
        </div>
      </div>
    </div>
  );
};

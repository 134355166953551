import React from "react";
import {ListTrainingComponent} from "./components/list.training";
import {WrapperDiv} from "../../hoc/wrapper-div";
import {HeaderListTraining} from "./components/header.list-training";
import {SubscribeListTraining} from "./components/subscribe.list-training";
import {InfoListTraining} from "./components/info.list-training";
import {Breadcrumb} from "../../components/breadcrumb";

export const ListTraining = ({match}) => {
  return (
    <div className="list-training">
      <HeaderListTraining id={match.params.id} />
      <Breadcrumb section={match.params.id} />
      <WrapperDiv>
        <ListTrainingComponent id={match.params.id} />
        <SubscribeListTraining />
        <InfoListTraining />
      </WrapperDiv>
    </div>
  );
};

import React, {useEffect, useState} from "react";
import {Row} from "antd";
import {inject, observer} from "mobx-react";
import {ItemSectionTraining} from "./item-section.training";
import {EmptyData} from "../../../components/empty-data";

export const ListSectionsTraining = inject("store")(
  observer(({store: {api}, section}) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
      api.getTrainingSection(section).then((data) => {
        setItems(data);
      });
    }, []);

    return (
      <div className="train-about">
        <Row gutter={[32, 40]}>
          {items &&
            items.map((item, index) => <ItemSectionTraining key={index} {...item} />)}
        </Row>
        {!items && <EmptyData />}
      </div>
    );
  })
);

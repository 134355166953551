import React, {useEffect, useState} from "react";
import {HeaderTrainingInfo} from "./components/header.training-info";
import {trainingConst} from "../../consts/training.const";
import {ContentTrainingInfo} from "./components/content.training-info";
import {WrapperDiv} from "../../hoc/wrapper-div";
import {RandomTrainingInfo} from "./components/random-training.training-info";
import {inject, observer} from "mobx-react";
import {Spin} from "antd";
import {Breadcrumb} from "../../components/breadcrumb";

export const TrainingInfo = inject("store")(
  observer(({store: {api}, match}) => {
    const [info, setInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      api.getTrainingInfo(match.params.id).then((data) => {
        setInfo(data);
        setIsLoading(true);
      });
    }, [match.params.id]);

    return (
      <div className="training-info">
        {!isLoading && <Spin className="spin" />}
        {isLoading && (
          <>
            <HeaderTrainingInfo
              title={info.title}
              section={trainingConst.find(({value}) => value === +info.type)}
            />
            <Breadcrumb training={match.params.id} />
            <div className="training-info--content">
              <WrapperDiv>
                <ContentTrainingInfo {...info} info={info} />
              </WrapperDiv>
            </div>
          </>
        )}
        <RandomTrainingInfo />
      </div>
    );
  })
);

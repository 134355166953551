import React from "react";
import {Avatar} from "antd";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";

export const UserFeedback = ({img, userName, position}) => {
  return (
    <div className="item-carousel--user">
      <div>
        <Avatar src={img} size={64} icon={<UserOutlined />} />
      </div>
      <div className="item-carousel--user-name">
        <div>{userName}</div>
        <div>{position}</div>
      </div>
    </div>
  );
};

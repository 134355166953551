import axiosInstance from "../../api/api";
import {constNumFeedbackRand, constNumTrainingMaxRand} from "../../consts/nums.const";

export class ApiAction {
  getConstant() {
    return axiosInstance.get("constant").then(({data}) => data);
  }

  getTeam() {
    return axiosInstance.get("team").then(({data}) => data);
  }

  getFeedback() {
    const config = {params: {num: this.numFeedback}};
    return axiosInstance.get("feedback", config).then(({data}) => data);
  }

  getFeedbackRand() {
    const config = {params: {num: constNumFeedbackRand}};
    return axiosInstance.get("feedback-rand", config).then(({data}) => data);
  }

  getTrainingSection(section) {
    const config = {params: {section}};
    return axiosInstance.get("training-section", config).then(({data}) => data);
  }

  getTrainingSectionInfo(id) {
    const config = {params: {id}};
    return axiosInstance.get("training-section", config).then(({data}) => data);
  }

  getTraining(sub_section) {
    const config = {params: {sub_section}};
    return axiosInstance.get("training", config).then(({data}) => data);
  }

  getTrainingCorporat() {
    return axiosInstance.get("training-corporat").then(({data}) => data);
  }

  getTrainingInfo(id) {
    const config = {params: {id}};
    return axiosInstance.get("training", config).then(({data}) => data);
  }

  getTrainingLast() {
    const config = {params: {num: this.numTrainingMain}};
    return axiosInstance.get("training-last", config).then(({data}) => data);
  }

  getTrainingRand() {
    const config = {params: {num: constNumTrainingMaxRand}};
    return axiosInstance.get("training-rand", config).then(({data}) => data);
  }

  getTrainingNum() {
    return axiosInstance.get("training-num").then(({data}) => data);
  }

  setNumFeedback(value) {
    this.numFeedback = value;
  }

  setNumTrainingMain(value) {
    this.numTrainingMain = value;
  }
}

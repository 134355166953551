import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {HeaderComponent} from "../components/header";
import {FooterComponent} from "../components/footer";
import {Sidebar} from "../components/sidebar";
import {MainPage} from "../pages/Main/Main";
import {TrainigPage} from "../pages/Training/Training";
import {CorporatPage} from "../pages/Corporat/Corporat";
import {NotFound} from "../pages/NotFoud/NotFound";
import {SupportModal} from "../pages/ModalSupport/support.modal";
import {About} from "../pages/About/About";
import {Feedback} from "../pages/Feedback/Feedback";
import {Contacts} from "../pages/Contacts/Contacts";
import {DistancePage} from "../pages/Distance/Distance";
import {ListTraining} from "../pages/ListTraining/ListTraining";
import {TrainingInfo} from "../pages/TrainingInfo/TrainingInfo";
import {PoliticsPage} from "../pages/Politics/Politics";
import {RulesPage} from "../pages/Rules/Rules";
import {Spin} from "antd";
import {setConst} from "../services/storage.service";

export const App = inject("store")(
  observer(({store: {helper, api}}) => {
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
      api.getConstant().then((data) => {
        setConst(data);
        setIsloading(true);
      });
    }, []);

    return (
      <>
        {!isLoading && <Spin className="spin main-spin" size="large" />}
        {isLoading && (
          <div className={`${helper.showBar ? " no-margin" : ""}`}>
            <Sidebar />
            <HeaderComponent />
            <Switch>
              <Route path="/" exact component={MainPage} />
              <Route path="/about" exact component={About} />
              <Route path="/feedback" exact component={Feedback} />
              <Route path="/contacts" exact component={Contacts} />
              <Route path="/distance" exact component={DistancePage} />
              <Route path="/courses" exact component={TrainigPage} />
              <Route path="/training/:id(\d+)" component={ListTraining} />
              <Route path="/training-info/:id(\d+)" component={TrainingInfo} />
              <Route path="/corporat" exact component={CorporatPage} />
              <Route path="/politics" exact component={PoliticsPage} />
              <Route path="/rules" exact component={RulesPage} />
              <Route component={NotFound} />
            </Switch>
            <FooterComponent />
            <SupportModal />
          </div>
        )}
      </>
    );
  })
);

import React from "react";

export const MapContacts = () => {
  return (
    <div className="map-contacts">
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A4de1d2c2a5a6e8e68630e740306e6a15fe872222f793248e3e720d8a89e375c8&amp;source=constructor"
        width="100%"
        height="396"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

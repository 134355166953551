import React from "react";
import {SupportForm} from "./support.form";
import {getConst} from "../services/storage.service";

export const SupportComponent = () => {
  const email = getConst("email");
  const phone = getConst("phone");
  const phone_link = getConst("phone_link");

  return (
    <div className="support-component">
      <div>
        <h2>Напишите нам и получите оперативный ответ</h2>
        <label>
          <span>E-mail</span>
          <div>
            <a href={`mailto:${email}`} className="blue">
              {email}
            </a>
          </div>
        </label>
        <label>
          <span>Телефон</span>
          <div>
            <a href={`tel:${phone_link}`}>{phone}</a>
          </div>
        </label>
      </div>
      <div className="wrapperForm">
        <SupportForm />
      </div>
    </div>
  );
};

import React from "react";
import {UserFeedback} from "../../../components/user.feedback";

export const ItemCarouselFeedback = ({text, userName, position, img}) => {
  return (
    <div className="wrapper-item-carousel-feedback">
      <div className="item-carousel-feedback">
        <div className="quotes"></div>
        <div className="text">{text}</div>
        <div className="user-info">
          <UserFeedback img={img} userName={userName} position={position} />
        </div>
      </div>
    </div>
  );
};

import React from "react";
import {SpamMain} from "../../Main/components/spam.main";

export const SubscribeListTraining = () => {
  return (
    <div className="subscribe-list-training">
      <div>
        <div className="title">Следите за обновлениями</div>
        <SpamMain />
      </div>
      <div>
        <div className="man"></div>
      </div>
    </div>
  );
};

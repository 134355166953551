import React from "react";
import {SpamComponent} from "./spam";
import {NavLink} from "react-router-dom";
import {getConst} from "../services/storage.service";

export const FooterComponent = () => {
  const email = getConst("email");
  const link_instagramm = getConst("link_instagramm");
  const link_youtube = getConst("link_youtube");
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="top-line">
        <NavLink to="/">
          <div className="footer-logo"></div>
        </NavLink>
        <div>
          <h3>Академия</h3>
          <ul>
            <li>
              <NavLink to="/about">О нас</NavLink>
            </li>
            <li>
              <NavLink to="/feedback">Отзывы о нашей работе</NavLink>
            </li>
            <li>
              <NavLink to="/contacts">Контакты</NavLink>
            </li>
          </ul>
        </div>
        <div>
          <h3>Обучение</h3>
          <ul>
            <li>
              <NavLink to="/distance">Дистанционное обучение</NavLink>
            </li>
            <li>
              <NavLink to="/courses">Тренинги</NavLink>
            </li>
            <li>
              <NavLink to="/corporat">Корпоративные мероприятия</NavLink>
            </li>
          </ul>
        </div>
        <div className="col_3">
          <h3>Будь в курсе</h3>
          <div>
            Подпишитесь на рассылку интересных и полезных новостей, событий и курсов
            академии.
          </div>
          <div className="footer--spam">
            <SpamComponent />
          </div>
          <div>
            Подписываясь Вы соглашаетесь с{" "}
            <a href="/rules" target="_blank">
              правилами сайта
            </a>
          </div>
        </div>
      </div>
      <div className="bot-line">
        <div className="footer--info">
          © 2018-{currentYear} Корпоративный университет “Академия Finbridge”
        </div>
        <div className="footer--politic">
          <a href="/politics" target="_blank">
            Политика конфиденциальности
          </a>
        </div>
        <div className="footer--mail">
          <a href={`mailto:${email}`}>{email}</a>
          <a href={link_youtube} target="_blank" rel="noopener noreferrer">
            <div className="ico_youtube"></div>
          </a>
          <a href={link_instagramm} target="_blank" rel="noopener noreferrer">
            <div className="ico_instagramm"></div>
          </a>
        </div>
      </div>
    </div>
  );
};

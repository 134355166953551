import React from "react";
import {Button} from "antd";
import {FiArrowRight} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import {getConst} from "../../../services/storage.service";

export const SliderMain = () => {
  const title = getConst("title_main");
  const about = getConst("main_about_slider");
  return (
    <div className="slider-main">
      <div className="wrapper-line">
        <div className="line-1">корпоративный университет</div>
        <div className="line-2">{title}</div>
        <div className="line-3">{about}</div>
        <div className="line-4">
          <NavLink to="/about">
            <Button type="primary" className="arrow">
              Об академии <FiArrowRight />
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {ReactComponent as IcoPhone} from "../../../static/ico/ico_phone.svg";
import {ReactComponent as IcoEmail} from "../../../static/ico/ico_email.svg";

export const AdvancedAbout = inject("store")(
  observer(({store: {helper}}) => {
    const [worker, setWorker] = useState();

    useEffect(() => {
      setWorker(helper.workerInfo);
    }, [helper.workerInfo]);

    return (
      <div className="advanced-about">
        <div className="img">
          <img src={worker?.img} width="100%" />
        </div>
        <div className="info">
          <div className="name">{worker?.name}</div>
          <div className="position">{worker?.position}</div>
          <div className="about">{worker?.about}</div>
          <div className="contact">Контакты</div>
          <div className="email">
            <IcoEmail />
            <a href={`mailto:${worker?.email}`}>{worker?.email}</a>
          </div>
          <div className="phone">
            <IcoPhone />
            <a href={`tel:${worker?.phone}`}>{worker?.phone}</a>
          </div>
        </div>
      </div>
    );
  })
);

import axiosInstance from "../../api/api";

export class SupportAction {
  sendEnrollment(data) {
    return axiosInstance.post("enrollment", data).then(({data}) => data);
  }

  sendSupport(data) {
    return axiosInstance.post("support", data).then(({data}) => data);
  }

  sendSubscribe(data) {
    return axiosInstance.post("subscribe", data).then(({data}) => data);
  }
}

import React from "react";
import {HeaderFeedback} from "./components/header.feedback";
import {ListFeedback} from "./components/list.feedback";

export const Feedback = () => {
  return (
    <>
      <HeaderFeedback />
      <ListFeedback />
    </>
  );
};

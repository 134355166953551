export const trainingConst = [
  {
    title: "онлайн-курс",
    label: "Онлайн-курс",
    value: 1,
    className: "bg-online",
  },
  {
    title: "вебинар",
    label: "Вебинар",
    value: 2,
    className: "bg-vebinar",
  },
  {
    title: "тренинг",
    label: "Тренинг",
    value: 3,
    className: "bg-training",
  },
  {
    title: "мероприятие",
    label: "Мероприятие",
    value: 4,
    className: "bg-meeting",
  },
];

import * as React from "react";
import {Input} from "antd";

export const InputReactiveFormComponent = ({handler, touched, hasError, meta}) => {
  return (
    <label
      className={
        touched && (hasError("required") || hasError("minLength") || hasError("email"))
          ? "input-component Error"
          : "input-component"
      }
    >
      <Input type={meta.type || "text"} placeholder={meta.placeholder} {...handler()} />
    </label>
  );
};

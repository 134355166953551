import React, {useEffect, useState} from "react";
import {Button, Row} from "antd";
import {FiArrowDown} from "react-icons/fi";
import {WrapperDiv} from "../../../hoc/wrapper-div";
import {ItemNew} from "../../Main/components/item.new";
import {inject, observer} from "mobx-react";
import {
  constNumTrainingMaxRand,
  constNumTrainingRand,
  constNumTrainingStepRand,
} from "../../../consts/nums.const";

export const RandomTrainingInfo = inject("store")(
  observer(({store: {api}}) => {
    const [originItem, setOriginItem] = useState([]);
    const [items, setItems] = useState([]);
    const [numItem, setNumItem] = useState(constNumTrainingRand);

    useEffect(() => {
      api.getTrainingRand().then((data) => {
        setOriginItem(data.data);
        setItems(data.data.filter((item, index) => index < numItem));
      });
    }, []);

    const showMore = () => {
      const newNum = numItem + constNumTrainingStepRand;
      setItems(originItem.filter((item, index) => index < newNum));
      setNumItem(newNum);
    };

    return (
      <WrapperDiv>
        <div className="new-main random">
          <h2>Также Вас может заинтересовать</h2>
          <Row gutter={[16, 24]}>
            {items.map((item, index) => (
              <ItemNew {...item} key={index} />
            ))}
          </Row>
          {items.length < originItem.length && items.length < constNumTrainingMaxRand && (
            <div className="wrapper-btn">
              <Button type="primary" className="arrow" onClick={showMore}>
                ПОКАЗАТЬ ЕЩЁ <FiArrowDown />
              </Button>
            </div>
          )}
        </div>
      </WrapperDiv>
    );
  })
);

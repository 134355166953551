import React, {useEffect, useState} from "react";
import {Carousel} from "antd";
import {ItemCarousel} from "./item.carousel";
import {inject, observer} from "mobx-react";

export const FeedbacksMain = inject("store")(
  observer(({store: {api}}) => {
    const [feedback, setFeedback] = useState([]);

    useEffect(() => {
      api.getFeedbackRand().then((data) => {
        setFeedback(data);
      });
    }, []);

    return (
      <div className="feedbacks-main">
        <div className="wrapper-quotes">
          <div className="quotes"></div>
        </div>
        <div className="feedbacks-main--carousel">
          <Carousel autoplaySpeed={7000} autoplay>
            {feedback.map(({text, position, user_name, img}, index) => (
              <ItemCarousel
                key={index}
                text={text}
                position={position}
                userName={user_name}
                img={img}
              />
            ))}
          </Carousel>
        </div>
      </div>
    );
  })
);

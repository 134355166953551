import React from "react";
import {HeaderDistance} from "./components/header.distance";
import {ContentDistance} from "./components/content.distance";
import {Breadcrumb} from "../../components/breadcrumb";

export const DistancePage = () => {
  return (
    <div className="trainings-page">
      <HeaderDistance />
      <Breadcrumb />
      <ContentDistance />
    </div>
  );
};

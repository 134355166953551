import React, {useCallback} from "react";
import {Button} from "antd";
import {FiArrowRight} from "react-icons/fi";
import {inject, observer} from "mobx-react";
import {modalNames} from "../../../consts/modal.const";
import {getConst} from "../../../services/storage.service";

export const QuestionMain = inject("store")(
  observer(({store: {helper}}) => {
    const handleSupport = useCallback(() => {
      helper.updateModalName(modalNames.SUPPORT);
    }, [helper]);

    const about1 = getConst("question_text_1");
    const about2 = getConst("question_text_2");

    return (
      <div className="question-main">
        <div className="question-main--button">
          Хотите задать вопрос или оставить заявку на тренинг?
          <div className="wrapper-btn">
            <Button type="primary" className="arrow" onClick={handleSupport}>
              Задать вопрос <FiArrowRight />
            </Button>
          </div>
        </div>
        <div className="question-main--text">
          <div>{about1}</div>
          <div>{about2}</div>
        </div>
      </div>
    );
  })
);

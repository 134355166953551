import React, {useEffect, useState} from "react";
import {WrapperDiv} from "../../../hoc/wrapper-div";
import {Button, Row, Spin} from "antd";
import {FiArrowDown} from "react-icons/fi";
import {ItemFeedback} from "./item.feedback";
import {inject, observer} from "mobx-react";
import {constNumFeedbackStep} from "../../../consts/nums.const";
import {EmptyData} from "../../../components/empty-data";

export const ListFeedback = inject("store")(
  observer(({store: {api}}) => {
    const [feedback, setFeedback] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showBtn, setShowBtn] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    useEffect(() => {
      api.getFeedback().then((data) => {
        setFeedback(data.data);
        setIsLoading(true);
        setLoadingBtn(false);
        setShowBtn(data.showMore);
      });
    }, [api.numFeedback]);

    const showMore = () => {
      setLoadingBtn(true);
      api.setNumFeedback(api.numFeedback + constNumFeedbackStep);
    };
    return (
      <div className="list-feedback">
        <WrapperDiv>
          <h2>Отзывы о нашей работе</h2>
          <div className="list">
            {!isLoading && <Spin className="spin" />}
            {isLoading && (
              <Row gutter={[32, 16]}>
                {feedback &&
                  feedback.map((item, index) => <ItemFeedback key={index} {...item} />)}
              </Row>
            )}
            {!feedback && <EmptyData />}
          </div>
          {showBtn && (
            <div className="wrapper-btn">
              <Button
                type="primary"
                className="arrow"
                onClick={showMore}
                loading={loadingBtn}
              >
                ПОКАЗАТЬ ЕЩЁ <FiArrowDown />
              </Button>
            </div>
          )}
        </WrapperDiv>
      </div>
    );
  })
);

import React, {useCallback, useEffect, useState} from "react";
import {Row, Spin} from "antd";
import {ItemTeamAbout} from "./item-team.about";
import {ItemBtnAbout} from "./item-btn.about";
import {inject, observer} from "mobx-react";
import {modalNames} from "../../../consts/modal.const";
import {EmptyData} from "../../../components/empty-data";

export const TeamAbout = inject("store")(
  observer(({store: {helper, api}}) => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      api.getTeam().then((data) => {
        setItems(data);
        setIsLoading(true);
      });
    }, []);

    const workerInfo = useCallback(
      (idWorker) => {
        helper.workerInfo = items.find(({id}) => id === idWorker);
        helper.updateModalName(modalNames.WORKER_INFO);
      },
      [items]
    );

    return (
      <div className="team-about">
        {!isLoading && <Spin className="spin" />}
        {!items && <EmptyData />}
        {isLoading && (
          <Row gutter={[32, 40]}>
            {items &&
              items.map((item, index) => (
                <ItemTeamAbout key={index} {...item} handleClick={workerInfo} />
              ))}
            <ItemBtnAbout />
          </Row>
        )}
      </div>
    );
  })
);

import {decorate, observable} from "mobx";
import {ApiAction} from "./api.action";
import {constNumFeedback, constNumTraining} from "../../consts/nums.const";

class ApiStore extends ApiAction {
  numFeedback = constNumFeedback;
  numTrainingMain = constNumTraining;
}

// eslint-disable-next-line no-class-assign
ApiStore = decorate(ApiStore, {
  numFeedback: observable,
  numTrainingMain: observable,
});

export default new ApiStore();

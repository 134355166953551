import React from "react";
import {UserFeedback} from "../../../components/user.feedback";

export const ItemCarousel = ({text, userName, position, img}) => {
  return (
    <div className="item-carousel">
      <div className="item-carousel--text">{text}</div>
      <UserFeedback img={img} userName={userName} position={position} />
    </div>
  );
};

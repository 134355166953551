import React from "react";
import {WrapperDiv} from "../../hoc/wrapper-div";
import {getConst} from "../../services/storage.service";

export const PoliticsPage = () => {
  const about = getConst("politics_page");

  return (
    <WrapperDiv>
      <h1>Политика конфиденциальности</h1>
      <div className="div-text">{about}</div>
    </WrapperDiv>
  );
};

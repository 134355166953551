import React from "react";
import {WrapperDiv} from "../../hoc/wrapper-div";
import {getConst} from "../../services/storage.service";

export const RulesPage = () => {
  const about = getConst("rules_page");

  return (
    <WrapperDiv>
      <h1>Правила Сайта</h1>
      <div className="div-text">{about}</div>
    </WrapperDiv>
  );
};

import React, {useEffect, useState} from "react";
import {WrapperDiv} from "../../../hoc/wrapper-div";
import {inject, observer} from "mobx-react";

export const HeaderListTraining = inject("store")(
  observer(({store: {api}, id}) => {
    const [title, setTitle] = useState();
    const [about, setAbout] = useState();

    useEffect(() => {
      api.getTrainingSectionInfo(id).then((data) => {
        setTitle(data.name);
        setAbout(data.about);
      });
    }, []);

    return (
      <div className="header-train img-distance">
        <WrapperDiv>
          <div className="wrapper-header-train">
            <div className="title">{title}</div>
            <div className="text">{about}</div>
          </div>
        </WrapperDiv>
      </div>
    );
  })
);

import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";

export const MenMain = inject("store")(
  observer(({store: {api}}) => {
    const [distance, setDistance] = useState("-");
    const [training, setTraining] = useState("-");
    const [corporat, setCorporat] = useState("-");

    useEffect(() => {
      api.getTrainingNum().then((data) => {
        setDistance(data.training_distance);
        setTraining(data.training_training);
        setCorporat(data.training_corporat);
      });
    }, []);

    return (
      <div className="men-main">
        <div className="men-main--info">
          <div className="men-main--icons">
            <div>
              <div className="icon online"></div>
              <div className="info">
                <div>{distance}</div>
                <div>Дистанционных курсов</div>
              </div>
            </div>
            <div>
              <div className="icon training"></div>
              <div className="info">
                <div>{training}</div>
                <div>Тренинга</div>
              </div>
            </div>
            <div className="ico-third">
              <div className="icon corporat"></div>
              <div className="info two-line">
                <div>{corporat}</div>
                <div>Корпоративных мероприятий</div>
              </div>
            </div>
          </div>
          <div className="men-main--text">
            Список мероприятий и учебных материалов академии постоянно расширяется.
            Следить за обновлениями просто - оформите подписку на рассылку.
          </div>
        </div>
        <div className="men-main--men"></div>
      </div>
    );
  })
);

import {inject, observer} from "mobx-react";
import React, {useCallback} from "react";
import {FiMenu} from "react-icons/fi";
import {FiHeadphones} from "react-icons/fi";
import {Button} from "antd";
import {NavLink} from "react-router-dom";
import {modalNames} from "../consts/modal.const";

export const HeaderComponent = inject("store")(
  observer(({store: {helper}}) => {
    const click = useCallback(
      (value) => {
        helper.toggleSidebar(value);
      },
      [helper]
    );

    const handleSupport = useCallback(() => {
      helper.updateModalName(modalNames.SUPPORT);
    }, [helper]);

    return (
      <div className="header">
        <div className="header--burger">
          <FiMenu
            onClick={() => click(!helper.showBar)}
            className="header-menu-icon"
            size={24}
          />
          <NavLink to="/">
            <div className="header--logo-1024"></div>
          </NavLink>
        </div>
        <NavLink to="/">
          <div className="header--logo"></div>
        </NavLink>

        <div className="header--phone">
          <div className="phone-number">
            <div className="phone-number--name">
              Для внутренней<br></br>связи
            </div>
            <div className="phone-number--ico"></div>
            <div className="phone-number--number">354</div>
          </div>
          <div>
            <Button type="primary" onClick={handleSupport}>
              <FiHeadphones />
              Связаться
            </Button>
          </div>
        </div>
      </div>
    );
  })
);

import React, {useCallback} from "react";
import {Col} from "antd";
import imgSrc from "../../../static/img/btn_team.png";
import {inject, observer} from "mobx-react";
import {modalNames} from "../../../consts/modal.const";

export const ItemBtnAbout = inject("store")(
  observer(({store: {helper}}) => {
    const handleSupport = useCallback(() => {
      helper.updateModalName(modalNames.SUPPORT);
    }, [helper]);

    return (
      <Col xs={24} md={8} lg={6} xl={6}>
        <div className="item-team" onClick={handleSupport}>
          <div className="img">
            <img width="100%" src={imgSrc} className="img-shadow" />
          </div>
        </div>
      </Col>
    );
  })
);

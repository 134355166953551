export const setStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getStorage = (key) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : undefined;
};

export const getConst = (key) => {
  const value = localStorage.getItem("constant");

  return value ? JSON.parse(value)[key].replace(/\n/g, "<br>") : "-";
};

export const setConst = (data) => {
  localStorage.setItem("constant", JSON.stringify(data));
};

import React from "react";
import {ReactComponent as IcoPublication} from "../../../static/ico/ico_publication.svg";
import {ReactComponent as IcoTime} from "../../../static/ico/ico_time.svg";
import {ReactComponent as IcoPlace} from "../../../static/ico/ico_place.svg";
import {ReactComponent as IcoTarget} from "../../../static/ico/ico_target.svg";
import {ReactComponent as IcoUsers} from "../../../static/ico/ico_num_users.svg";

export const InfoTrainingInfo = ({
  publication,
  img,
  time,
  place,
  target,
  users,
  link,
}) => {
  return (
    <div className="info-training-info">
      <div className="img">
        {link ? (
          <a href={`http://${link}`} target="_blank" rel="noopener noreferrer">
            <div className="wrapper_img">
              <img src={img} />
              <div className="img-overlay"></div>
            </div>
          </a>
        ) : (
          <img src={img} />
        )}
      </div>
      <div className="wrapper-info">
        {publication && (
          <div className="publication">
            <div className="ico">
              <IcoPublication />
            </div>
            <div>
              <div>Дата</div>
              <div>{publication}</div>
            </div>
          </div>
        )}
        {time && (
          <div className="time">
            <div className="ico">
              <IcoTime />
            </div>
            <div>
              <div>Продолжительность</div>
              <div>{time}</div>
            </div>
          </div>
        )}
        {place && (
          <div className="place">
            <div className="ico">
              <IcoPlace />
            </div>
            <div>
              <div>Место проведения</div>
              <div>{place}</div>
            </div>
          </div>
        )}
        {target && (
          <div className="target">
            <div className="ico">
              <IcoTarget />
            </div>
            <div>
              <div>Целевая аудитория</div>
              <div>{target}</div>
            </div>
          </div>
        )}
        {users && (
          <div className="users">
            <div className="ico">
              {" "}
              <IcoUsers />
            </div>
            <div>
              <div>Участников</div>
              <div>{users}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import React from "react";
import {Col} from "antd";
import {ItemTraining} from "./item.training";

export const LinkWrapper = ({title, about, img, time, is_new, link}) => {
  return (
    <Col xs={24} md={12} lg={8} xl={6}>
      <a href={`http://${link}`} target="_blank" rel="noopener noreferrer">
        <ItemTraining title={title} about={about} img={img} time={time} is_new={is_new} />
      </a>
    </Col>
  );
};

import React, {useEffect, useState} from "react";
import {WrapperDiv} from "../../../hoc/wrapper-div";
import {ItemCarouselFeedback} from "./item.carousel.feedback";
import {Carousel} from "antd";
import {inject, observer} from "mobx-react";

export const HeaderFeedback = inject("store")(
  observer(({store: {api}}) => {
    const [feedback, setFeedback] = useState([]);

    useEffect(() => {
      api.getFeedbackRand().then((data) => {
        setFeedback(data);
      });
    }, []);

    return (
      <div className="header-feedback">
        <WrapperDiv>
          <div className="header-feedback--wrapper">
            <Carousel autoplaySpeed={7000} autoplay>
              {feedback &&
                feedback.map(({text, position, user_name, img}, index) => (
                  <ItemCarouselFeedback
                    key={index}
                    text={text}
                    position={position}
                    userName={user_name}
                    img={img}
                  />
                ))}
            </Carousel>
          </div>
        </WrapperDiv>
      </div>
    );
  })
);

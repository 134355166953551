import React from "react";
import {WrapperDiv} from "../../../hoc/wrapper-div";
import {getConst} from "../../../services/storage.service";
import {ReactComponent as IcoYoutube} from "../../../static/ico/ico_youtube_1c.svg";
import {ReactComponent as IcoArrow} from "../../../static/ico/ico_arrow.svg";

export const HeaderDistance = () => {
  const title = getConst("distance_header_title");
  const about = getConst("distance_header_about");
  const link_1c = getConst("link_1c");

  return (
    <div className="header-train img-distance">
      <WrapperDiv>
        <div className="wrapper-header-train">
          <div className="title">
            {title}
            <div className="text" dangerouslySetInnerHTML={{__html: about}}></div>
          </div>

          <a href={link_1c} target="_blank" rel="noopener noreferrer">
            <div className="youtube_link">
              <div>
                <IcoYoutube />
              </div>
              <div className="title_link">
                Как учиться в 1С: Корпоративный университет
              </div>
              <div>
                <IcoArrow />
              </div>
            </div>
          </a>
        </div>
      </WrapperDiv>
    </div>
  );
};

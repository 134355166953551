import {decorate, observable} from "mobx";
import {HelperAction} from "./helper.action";

class HelperStore extends HelperAction {
  showBar = false;
  modalName = undefined;
  workerInfo = undefined;
}

// eslint-disable-next-line no-class-assign
HelperStore = decorate(HelperStore, {
  showBar: observable,
  modalName: observable,
  workerInfo: observable,
});

export default new HelperStore();

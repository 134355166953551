import React, {useEffect, useState} from "react";
import {Row, Spin} from "antd";
import {inject, observer} from "mobx-react";
import {SelectComponent} from "../../../components/select";
import {trainingConst} from "../../../consts/training.const";
import {EmptyData} from "../../../components/empty-data";
import {LinkWrapper} from "./link-wrapper";
import {NavlinkWrapeer} from "./navlink-wrapper";

export const ListTrainingComponent = inject("store")(
  observer(({store: {api}, id, corporat}) => {
    const [originItems, setOriginItems] = useState([]);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if (corporat) {
        api.getTrainingCorporat().then((data) => {
          setOriginItems(data);
          setItems(data);
          setIsLoading(true);
        });
      } else {
        api.getTraining(id).then((data) => {
          setOriginItems(data);
          setItems(data);
          setIsLoading(true);
        });
      }
    }, []);

    const updateSelect = (value) => {
      if (value) {
        if (originItems) {
          setItems(originItems.filter(({type}) => type === value.value));
        }
      } else {
        setItems(originItems);
      }
    };

    return (
      <div className="list-training-component">
        <div className="select-section">
          <SelectComponent
            data={trainingConst}
            labelInValue={true}
            handleChange={updateSelect}
            placeholder="Все"
            allowClear
            selectClassName="list-training-select"
            disabled={!originItems}
          />
        </div>
        {!isLoading && <Spin className="spin" />}
        {isLoading && (
          <Row gutter={[32, 40]}>
            {items &&
              items.map((item, index) =>
                +item.section === 1 ? (
                  <LinkWrapper key={index} {...item} />
                ) : (
                  <NavlinkWrapeer key={index} {...item} />
                )
              )}
          </Row>
        )}
        {(!items || !items.length) && <EmptyData />}
      </div>
    );
  })
);

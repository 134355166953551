import React from "react";
import {Col} from "antd";
import {NavLink} from "react-router-dom";
import {ItemTraining} from "./item.training";

export const NavlinkWrapeer = ({id, title, about, img, time, is_new}) => {
  return (
    <Col xs={24} md={12} lg={8} xl={6}>
      <NavLink to={`/training-info/${id}`}>
        <ItemTraining title={title} about={about} img={img} time={time} is_new={is_new} />
      </NavLink>
    </Col>
  );
};

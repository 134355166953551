import React from "react";
import {SliderMain} from "./components/slider.main";
import {UnderSliderMain} from "./components/under-slider";
import {NewMain} from "./components/new.main";
import {MenMain} from "./components/men.main";
import {SubscribeMain} from "./components/subscribe.main";
import {QuestionMain} from "./components/question.main";
import {FeedbacksMain} from "./components/feedbacks.main";

export const MainPage = () => {
  return (
    <div className="main-page">
      <SliderMain />
      <UnderSliderMain />
      <NewMain />
      <MenMain />
      <SubscribeMain />
      <QuestionMain />
      <FeedbacksMain />
    </div>
  );
};

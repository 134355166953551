import React from "react";
import {Col} from "antd";
import {trainingConst} from "../../../consts/training.const";
import {NavLink} from "react-router-dom";

export const ItemNew = ({
  id,
  title,
  about,
  type,
  publication,
  show_publication,
  date,
  show_date,
  img,
  className,
}) => {
  const training = trainingConst.find((item) => item.value === +type);

  return (
    <Col xs={24} md={12} lg={8} xl={8} className={className}>
      <NavLink to={`/training-info/${id}`}>
        <div className="item-new">
          <div className="item-new--img">
            <div className="img-wrapper">
              <img src={img} width="100%" />
              <div className="item-new--img-overlay"></div>
            </div>
            {show_publication && (
              <div className="item-new--img-calendar">
                <span dangerouslySetInnerHTML={{__html: publication}}></span>
              </div>
            )}
          </div>
          <div className="item-new--date">
            <div>{show_date ? date : " "}</div>
            <div className={training ? training.className : ""}>
              {training ? training.title : "-"}
            </div>
          </div>
          <div className="item-new--title">{title}</div>
          <div className="item-new--about">{about}</div>
        </div>
      </NavLink>
    </Col>
  );
};

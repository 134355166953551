import React from "react";
import {Provider} from "mobx-react";
import {HashRouter} from "react-router-dom";
import {App} from "./App";
import store from "../store";
import ScrollToTop from "../hoc/scroll-to-top";

export default function Routes() {
  return (
    <Provider store={store}>
      <HashRouter hashType="slash">
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </HashRouter>
    </Provider>
  );
}

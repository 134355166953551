export const constNumFeedback = 10; // кол-во первоначально загруженных отзывов
export const constNumFeedbackStep = 10; // шаг добавления отзывов
export const constNumFeedbackRand = 3; // кол-во случайных отзывов

export const constNumTraining = 5; // кол-во первоначально загруженных тренингов на главной
export const constNumTrainingStep = 3; // кол-во добавляемых
export const constNumTrainingMax = 11; // кол-во максимально загруженых тренинга на главной

export const constNumTrainingRand = 3; // кол-во первоначально загруженных тренингов на странице тренинга
export const constNumTrainingStepRand = 3; // кол-во добавляемых
export const constNumTrainingMaxRand = 9; // кол-во максимально загруженых тренинга на странице тренинга

import React from "react";
import {WrapperDiv} from "../../../hoc/wrapper-div";
import {getConst} from "../../../services/storage.service";

export const HeaderCorporat = () => {
  const title = getConst("corporat_header_title");
  const about = getConst("corporat_header_about");

  return (
    <div className="header-train img-training">
      <WrapperDiv>
        <div className="wrapper-header-train">
          <div className="title">{title}</div>
          <div className="text" dangerouslySetInnerHTML={{__html: about}}></div>
        </div>
      </WrapperDiv>
    </div>
  );
};

import React from "react";
import {SpamMain} from "./spam.main";

export const SubscribeMain = () => {
  return (
    <div className="subscribe-main">
      <div>Подпишитесь на новости и оставайтесь в курсе</div>
      <div>
        <SpamMain />
      </div>
    </div>
  );
};

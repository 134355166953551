import React, {useEffect, useState} from "react";
import {WrapperDiv} from "../hoc/wrapper-div";
import {NavLink} from "react-router-dom";
import {inject, observer} from "mobx-react";

export const Breadcrumb = inject("store")(
  observer(({store: {helper}, section, training}) => {
    const [breadcrumb, setBreadcrumb] = useState();
    const mainLink = (
      <div>
        <NavLink to="/">FINBRIDGE ACADEMY</NavLink>
      </div>
    );
    const separator = <div className="separator"></div>;

    useEffect(() => {
      if (section) {
        helper.getBreadcrumb({section}).then((data) => {
          setBreadcrumb(showSectionLink(data[0]));
        });
      } else if (training) {
        helper.getBreadcrumb({training}).then((data) => {
          setBreadcrumb(showSubSectionLink(data));
        });
      } else {
        setBreadcrumb(mainLink);
      }
    }, []);

    const showSubSectionLink = (data) => {
      let name;
      if (data[1].link && data[1].name) {
        name = (
          <>
            {showSectionLink(data[0])} {separator}{" "}
            <NavLink to={`/training/${data[1].link}`}>{data[1].name}</NavLink>
          </>
        );
      } else {
        name = (
          <>
            {mainLink}
            {separator}
          </>
        );
      }
      return name;
    };

    const showSectionLink = (data) => {
      let name;

      if (data.link) {
        let link, nameLink;
        if (data.link === "1") {
          link = "/distance";
          nameLink = "Дистанционное обучение";
        } else if (data.link === "2") {
          link = "/courses";
          nameLink = "Тренинги";
        } else if (data.link === "3") {
          link = "/corporat";
          nameLink = "Корпоративные мероприятия";
        }
        name = (
          <>
            {mainLink} {separator} <NavLink to={link}>{nameLink}</NavLink>
          </>
        );
      } else {
        name = (
          <>
            {mainLink}
            {separator}
          </>
        );
      }
      return name;
    };

    return (
      <div className="breadcrumb">
        <WrapperDiv>
          <div className="breadcrumb-wrapper">{breadcrumb}</div>
        </WrapperDiv>
      </div>
    );
  })
);

import React from "react";
import {WrapperDiv} from "../../../hoc/wrapper-div";
import {ListSectionsTraining} from "../../ListTraining/components/list-sections.training";

export const ContentDistance = () => {
  return (
    <div className="content">
      <WrapperDiv>
        <ListSectionsTraining section={1} />
      </WrapperDiv>
    </div>
  );
};
